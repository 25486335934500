import type { RootState } from "./store/store.ts";
import { useSelector } from 'react-redux';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Generate from "./pages/Generate/Generate.tsx";
import Header from './components/Header.tsx';
import PrivateRoute from './components/PrivateRoute.tsx';
import Login from './pages/Login/Login.tsx';
import History from './pages/History/History.tsx';
import Agreement from "./pages/UserAgreement/Agreement.tsx";

export default function App() {
  const token = useSelector((state: RootState) => state.user.token);

  return (
    <main className="h-screen flex flex-col">
      <Router>
        {token ? <Header /> : ""}
        <Routes>
          <Route
            path="/"
            element={
              <PrivateRoute isAuthenticated={token != ""}>
                <Generate />
              </PrivateRoute>
            }
          />
          <Route path="/login" element={<Login />} />
          <Route path="/agreement" element={<Agreement />} />
          <Route path="/history" element={
            <PrivateRoute isAuthenticated={token != ""}>
              <History />
            </PrivateRoute>
          }
          />
          <Route path="*" element={<Login />} />
        </Routes>
      </Router>
    </main>
  );
}
