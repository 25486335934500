import { useEffect, useState } from "react";
// @ts-ignore
import param from "@/mapgen/config.js";
import "./map.css";
import { MapgenProps } from "@/types";

function Mapgen({ callback = () => { }, loading }: MapgenProps) {
    const [render, setRender] = useState<any>(null);
    const [paint, setPaint] = useState<any>(null);

    useEffect(() => {
        async function execMapgen() {
            let { makeMesh } = await import("@/mapgen/mesh");
            let { main } = await import("@/mapgen/mapgen4");
            let { PaintEventHandler } = await import("@/mapgen/tool");
            let mesh = await makeMesh();

            let render = main(mesh);

            setRender(render);
            let action = new PaintEventHandler("mapgen4", "map");
            action.reset("reset");
            setPaint(action);
        };
        execMapgen();
    }, []);

    useEffect(() => {
        updateUI()
    },[loading]);

    function updateUI() {
        const btns = document.querySelector("#action")?.querySelectorAll("button");
        const generatebtn = document.querySelector("#generatebtn") as HTMLButtonElement;
        if(!btns || !generatebtn) return
        for (const btn of btns) {
            btn.disabled = loading;
            btn.style.cursor = "pointer";
            generatebtn.style.cursor = "pointer";
        }
    }


    const send = async () => {
        //paint.disableAction();
        let blob: Blob = await saveOriginalImage();
        let blob2: Blob = await saveRedImage();
        callback(paint.vaillagePos, blob, blob2, paint.enableAction);
    }

    const saveRedImage = (): Promise<Blob> => {
        return new Promise((reslove) => {
            render.startDrawingLoop(true);
            render.updateView(param.render);
            render.screenshotCallback = () => {
                render.screenshotCanvas.toBlob((blob: any) => {
                    render.startDrawingLoop(false);
                    render.updateView(param.render);
                    reslove(blob);
                });
            };
        });
    }

    const saveOriginalImage = (): Promise<Blob> => {
        return new Promise((reslove) => {
            render.updateView(param.render);
            render.screenshotCallback = () => {
                render.screenshotCanvas.toBlob((blob: any) => {
                    reslove(blob);
                });
            };
        });
    };

    return (
        <>
            <div id="ui" className=" flex flex-col max-w-[90rem] text-sm relative">
                {loading ? <div id="ui-mask"></div> : ""}
                <div id="map">
                    {/* <div className="loading">Loading...</div> */}
                    <canvas id="mapgen4" width="2048" height="2048" />
                </div>
                <div className="flex flex-col bg-white" id="action">
                    <div className=" flex">
                        <div className="icon-type">
                            <button id="ocean" title="Ocean">
                                <svg viewBox="-50 -50 100 100">
                                    <path d="M -50,-30 q 25,-20 50,0 q 25,20 50,0 l 0,50 l -100,0 z" fill="hsl(240,50%,40%)" />
                                </svg>
                                <p>Ocean</p>
                            </button>
                            <button id="shallow" title="Water">
                                <svg viewBox="-50 -50 100 100">
                                    <path d="M -50,-20 q 15,20 30,0 q 20,20 40,0 q 15,20 30,0 l 0,40 l -100,0 z" fill="hsl(200,50%,70%)" />
                                </svg>
                                <p>Water</p>
                            </button>
                            <button id="valley" title="Valley">
                                <svg viewBox="-50 -50 100 100">
                                    <path d="M -50,-20 c 20,10 80,10 100,0 l 0,40 l -100,0 z" fill="hsl(100,40%,60%)" />
                                </svg>
                                <p>Valley</p>
                            </button>
                            <button id="mountain" title="Mountain">
                                <svg viewBox="-50 -50 100 100">
                                    <g fill="hsl(60,50%,40%)" stroke="white" strokeWidth="2">
                                        <path d="M -30,20 l 30,-60 l 30,60 z" />
                                        <path d="M -50,20 l 20,-40 l 20,40 z" />
                                        <path d="M 10,20 l 15,-30 l 15,30 z" />
                                    </g>
                                </svg>
                                <p>Mountains</p>
                            </button>
                        </div>
                        <div className="flex vaillage">
                            <button className="flex flex-col items-center justify-between px-1" id="vaillage">
                                <svg width="24" viewBox="0 -960 960 960" fill="#A96424">
                                    <path d="M160-120v-401l-84 64-36-48 120-91v-114h60v68l260-198 440 336-36 47-84-64v401H160Zm60-60h230v-160h60v160h230v-387L480-765 220-567v387Zm-60-580q0-46 32.5-78t77.5-32q21.25 0 35.63-15Q320-900 320-920h60q0 45-32.08 77.5Q315.83-810 270-810q-20 0-35 14.37-15 14.38-15 35.63h-60Zm60 580h520-520Z" />
                                </svg>
                                <p>+ Vaillage</p>
                            </button>
                            <button className="flex flex-col items-center justify-between px-1" id="deleteVaillage">
                                <svg width="24px" viewBox="0 -960 960 960" fill="#666666">
                                    <path d="M160-120v-401l-84 64-36-48 120-91v-114h60v68l260-198 440 336-36 47-84-64v401H160Zm60-60h230v-160h60v160h230v-387L480-765 220-567v387Zm-60-580q0-46 32.5-78t77.5-32q21.25 0 35.63-15Q320-900 320-920h60q0 45-32.08 77.5Q315.83-810 270-810q-20 0-35 14.37-15 14.38-15 35.63h-60Zm60 580h520-520Z" />
                                </svg>
                                <p>- Vaillage</p>
                            </button>
                            <button className="flex flex-col items-center justify-between px-1" id="reset">
                                <svg width="28px" viewBox="0 -960 960 960" fill="#78A75A">
                                    <path d="M451-122q-123-10-207-101t-84-216q0-77 35.5-145T295-695l43 43q-56 33-87 90.5T220-439q0 100 66 173t165 84v60Zm60 0v-60q100-12 165-84.5T741-439q0-109-75.5-184.5T481-699h-20l60 60-43 43-133-133 133-133 43 43-60 60h20q134 0 227 93.5T801-439q0 125-83.5 216T511-122Z" />
                                </svg>
                                <p>Reset</p>
                            </button>
                        </div>
                    </div>
                    <div className="pen">
                        <button id="tiny"><svg viewBox="-50 -50 100 100" color="#ffffff"><circle r="7" /></svg></button>
                        <button id="small"><svg viewBox="-50 -50 100 100" color="#ffffff"><circle r="20" /></svg></button>
                        <button id="medium"><svg viewBox="-50 -50 100 100" color="#ffffff"><circle r="35" /></svg></button>
                        <button id="large"><svg viewBox="-50 -50 100 100" color="#ffffff"><circle r="50" /></svg></button>
                    </div>
                </div>

                <div id="sliders" className=" hidden">
                    <button id="button-reset">Reset</button>
                </div>
            </div>
            <button
                className=" w-full justify-self-center rounded-md text-black py-2 mt-1 bg-white hover:bg-gray-200"
                onClick={send}
                disabled={loading}
                id="generatebtn"
            >
                {loading ? "Generating..." : "Generate 3D preview"}
            </button>
        </>
    );
};

export default Mapgen;