import request from "@/lib/http";
import { AxiosResponse } from "axios";

// 上传图片创建新任务
async function createTask(formData: FormData): Promise<AxiosResponse<any, any>> {
    let url = import.meta.env.VITE_APP_CREATE_TASK;
    return request({ method: 'post', url, data: formData });
}

// 同一张图片修改时，不创建新的任务
function revisionTask(formData: FormData, taskID: string): Promise<AxiosResponse<any, any>> {
    let url = import.meta.env.VITE_APP_REVISION_TASK + taskID;
    return request({ method: 'post', url, data: formData });
}

// 查询任务的状态
function taskStatus(taskID: string): Promise<AxiosResponse<any, any>> {
    let url = import.meta.env.VITE_APP_TASK_STATUS + taskID;
    return request.get(url);
}

// 确定任务CONFIRM
function confirmTask(taskID: string): Promise<AxiosResponse<any, any>> {
    let url = import.meta.env.VITE_APP_CONFIRM_TASK + taskID;
    return request.get(url);
}

// 关闭任务
function closeTask(taskID: string): Promise<AxiosResponse<any, any>> {
    let url = import.meta.env.VITE_APP_CLOSE_TASK + taskID;
    return request.get(url);
}

// 获取任务列表
function getTaskList(): Promise<AxiosResponse<any, any>> {
    let url = import.meta.env.VITE_APP_TASK_LIST;
    return request.get(url);
}

// 删除任务
function removeTask(taskID: string): Promise<AxiosResponse<any, any>> {
    let url = import.meta.env.VITE_APP_TASK_DELETE + taskID;
    return request.get(url);
}

// 获取任务列表
function taskRender(taskID: string): Promise<AxiosResponse<any, any>> {
    let url = import.meta.env.VITE_APP_TASK_RENDER + taskID;
    return request.get(url);
}

// 获取模型的url
function getModelURL(taskID: string): Promise<AxiosResponse<any, any>> {
    let url = import.meta.env.VITE_APP_ASSET_MODEL + taskID + "/scene.fbx";
    return request.get(url);
}

// 获取模型的url
function randomMap(): Promise<AxiosResponse<any, any>> {
    let url = import.meta.env.VITE_APP_RANDOM_MAP;
    return request.get(url, { responseType: 'arraybuffer' });
}

export { 
    createTask, 
    revisionTask, 
    taskStatus, 
    confirmTask, 
    closeTask, 
    getTaskList, 
    removeTask,
    taskRender,
    getModelURL,
    randomMap
};