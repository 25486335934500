import { useRef, useEffect, useState } from 'react';
import * as THREE from 'three';
import { FBXLoader, OrbitControls } from 'three-stdlib';

const ThreeScene = ({ modelUrl, isFull }: { modelUrl: string, isFull: boolean }) => {
    const mountRef = useRef<HTMLDivElement>(null);
    const heightRef = useRef<number>(0);
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const currentMount = mountRef.current;
        const originalWidth = currentMount!.clientWidth;
        const originalHeight = currentMount!.clientHeight;
        const originalWindowWidth  = window.innerWidth;
        const originalWindowHeight = window.innerHeight;
        
        const threeScene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(75, originalWidth / originalHeight, 0.1, 3000);
        // const renderer = new THREE.WebGLRenderer();
        const renderer = new THREE.WebGLRenderer();
   
        renderer.setSize(originalWidth, originalHeight);
        renderer.domElement.style.background = "#889BA4";
        renderer.domElement.style.borderRadius = "4px";
        currentMount!.appendChild(renderer.domElement);

        camera.position.set(650, 700, 0);
        camera.position.set(0, 800, 0);
        threeScene.background = new THREE.Color("#889BA4");

        const ambientLight = new THREE.AmbientLight(0xffffff, 1);
        threeScene.add(ambientLight);

        const ambientLight2 = new THREE.AmbientLight(0xffffff, 0.5);
        threeScene.add(ambientLight2);

        const gridHelper = new THREE.GridHelper(4000, 40);
        gridHelper.position.set(0, -100, 0);
        threeScene.add(gridHelper);
        // const material = new THREE.MeshLambertMaterial({ color: 0x00ff00 });
        // Load the FBX model
        const loader = new FBXLoader();
        loader.load(modelUrl, (model) => {
            threeScene.add(model);
            model.position.set(0, 0, 0);
            // Traverse all subgrids in the model
            model.traverse(function (child) {
                if (child instanceof THREE.Mesh) {
                    const mcolor = new THREE.Color("#ffffff");
                    child.material.color = mcolor;
                    // child.material = material;
                }
            });
            animate();

        }, (xhr) => {
            // console.log((xhr.loaded / xhr.total * 100) + '% loaded');
            setProgress((xhr.loaded / xhr.total * 100));
        }, (error) => {
            console.error('An error happened', error);
        });

        const animate = function () {
            requestAnimationFrame(animate);
            renderer.render(threeScene, camera);
        };
  
        var boxControls = new OrbitControls(camera, renderer.domElement);
        boxControls.addEventListener("change", function () {
            renderer.render(threeScene, camera);
        });

        // 当窗口变大或者变小的时候
        window.onresize = function () {
            let newHeight;
            if(window.innerHeight <=760) {
                newHeight = heightRef.current;
            }else{
                newHeight = heightRef.current = ( window.innerHeight -  originalWindowHeight) / 2 + originalHeight;
            };

            let newWidth =( window.innerWidth -  originalWindowWidth) + originalWidth
            renderer.setSize(newWidth , newHeight);
            renderer.render(threeScene, camera);
            camera.aspect = newWidth / newHeight;
            camera.updateProjectionMatrix(); // 需要更新的camera的
        }

        return () => {
            currentMount!.removeChild(renderer.domElement);
            threeScene.clear();
            renderer.dispose();
            camera.clear();
            window.onresize = null;
        };
    }, [isFull]);

    return (
        <div className='h-full relative'>
           <div ref={mountRef} style={{ width: '100%', height: '100%' }}></div>
            {
                progress === 100 ? "" :
                    <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                        <progress value={progress} max="100" className=' w-52' ></progress>
                        <p className=' text-white text-center'>{progress.toFixed(1)}%</p>
                    </div>
            }
        </div>
    );
};

export default ThreeScene;
