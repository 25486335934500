
// Key buttons
export const KEY_BUTTONS = {
    "W": ["w", "KeyW"],
    "S": ["s", "KeyS"],
    "A": ["a", "KeyA"],
    "D": ["d", "KeyD"],
    "V": ["v", "KeyV"],
    "Space": [" ", "Space"],
    "\u2191": ["ArrowUp", "ArrowUp"],
    "\u2193": ["ArrowDown", "ArrowDown"],
    "\u2190": ["ArrowLeft", "ArrowLeft"],
    "\u2192": ["ArrowRight", "ArrowRight"]
};

// Move controller
export const MOVE_ACTIONS = {
    "w": "move_forward",
    "s": "move_back",
    "a": "move_left",
    "d": "move_right",
    "v": "switch_view"
};

// View controller
export const VIEW_ACTIONS = {
    " ": "move_jump",
    "ArrowUp": "move_sight_top",
    "ArrowDown": "move_sight_down",
    "ArrowLeft": "move_sight_left",
    "ArrowRight": "move_sight_right"
};

// Single key controller
export class SingleKey {

    constructor(keys) {
        this.keys = keys;
        this.locks = {};
        this.onKeydownHandler = null;
        this.onKeyupHandler = null;
        document.addEventListener("keydown", this.keydownListener);
        document.addEventListener("keyup", this.keyupListener);
    }

    onKeydown(callback) {
        this.onKeydownHandler = callback;
    }

    onKeyup(callback) {
        this.onKeyupHandler = callback;
    }

    keydownListener = (e) => {
        if (!this.keys.includes(e.key)) return;
        e.preventDefault();
        if (this.locks[e.key]) return;
        this.locks[e.key] = true;
        this.onKeydownHandler && this.onKeydownHandler.call(null, e.key);
    }

    keyupListener = (e) => {
        if (!this.keys.includes(e.key)) return;
        e.preventDefault();
        delete this.locks[e.key];
        this.onKeyupHandler && this.onKeyupHandler.call(null, e.key);
    }

    destroy() {
        document.removeEventListener("keydown", this.keydownListener);
        document.removeEventListener("keyup", this.keyupListener);
    }

}

// Turbo key controller
export class TurboKey {
    
    constructor(keys, interval) {
        this.keys = keys;
        this.interval = interval || 50;
        this.onKeyHandler = null;
        this.timer = {};
        document.addEventListener("keydown", this.keydownListener);
        document.addEventListener("keyup", this.keyupListener);
    }

    onKey(callback) {
        this.onKeyHandler = callback;
    }

    keydownListener = (e) => {
        if (!this.keys.includes(e.key)) return;
        e.preventDefault();
        this.handleKeydown(e.key);
    }

    keyupListener = (e) => {
        if (!this.keys.includes(e.key)) return;
        e.preventDefault();
        this.handleKeyup(e.key);
    }

    handleKeydown(key) {
        if (this.timer[key]) return;
        this.timer[key] = setInterval(() => {
            this.onKeyHandler && this.onKeyHandler.call(null, key);
        }, this.interval);
    }

    handleKeyup(key) {
        clearInterval(this.timer[key]);
        delete this.timer[key];
    }

    destroy() {
        document.removeEventListener("keydown", this.keydownListener);
        document.removeEventListener("keyup", this.keyupListener);
        Object.keys(this.timer).forEach(key => {
            clearInterval(this.timer[key]);
        });
    }

}

// UI key bind
export class UIKeyBind {

    constructor() {
        this.events = [];
    }

    addEventListener(ui, name, handler) {
        ui.addEventListener(name, handler);
        this.events.push([ui, name, handler]);
    }

    addBind(ui, key, code) {
        let keyDownEvent = new KeyboardEvent("keydown", {
            key: key,
            code: code,
            bubbles: true,
            cancelable: true
        });
        let keyUpEvent = new KeyboardEvent("keyup", {
            key: key,
            code: code,
            bubbles: true,
            cancelable: true
        });
        this.addEventListener(ui, "mousedown", (e) => {
            e.preventDefault();
            document.dispatchEvent(keyDownEvent);
        });
        this.addEventListener(ui, "mouseup", (e) => {
            e.preventDefault();
            document.dispatchEvent(keyUpEvent);
        });
        this.addEventListener(ui, "touchstart", (e) => {
            document.dispatchEvent(keyDownEvent);
        });
        this.addEventListener(ui, "touchend", (e) => {
            document.dispatchEvent(keyUpEvent);
        });
        this.addEventListener(ui, "contextmenu", (e) => {
            e.preventDefault();
        });
    }

    destroy() {
        let item;
        while (item = this.events.shift()) {
            let [ui, name, handler] = item;
            ui.removeEventListener(name, handler);
        }
    }

}
