import { GenerateState, ImageList, Task } from "@/types";
import request from "@/lib/http";
import { message } from "antd";

function disabledButton(): void {
    document.querySelectorAll('button').forEach(button => {
        button.disabled = true;
        button.style.cursor = "not-allowed";
    });
};

function enabledButton(): void {
    document.querySelectorAll('button').forEach(button => {
        button.disabled = false;
        button.style.cursor = "pointer";
    });
}

const TOKENKEY = "token_key";

function setLocalToken(token: string): void {
    localStorage.setItem(TOKENKEY, token);
}

function getLocalToken(): string | null {
    return localStorage.getItem(TOKENKEY);
}

function clearLocalToken(): void {
    localStorage.removeItem(TOKENKEY);
    localStorage.removeItem("photoURL");
}

function toSocketSend(action: string, speed: number, ws: WebSocket | null): void {
    var json = JSON.stringify({
        "action": action,
        "speed": speed
    });
    console.log("json:", json);
    if (ws?.readyState === WebSocket.OPEN) {
        ws?.send(json);
    }
}

function GenerateUEProgress(currentState: number, taskAhead: number, renderAhead: number): string {
    if (currentState === 220) {
        return `Waiting to be processed in the queue, ${taskAhead} task(s) ahead.`
    } else if (currentState === 230) {
        return "Generating model file, step 1 / 2";
    } else if (currentState === 260) {
        return `Waiting to be processed in the queue, ${renderAhead} task(s) ahead.`
    }
    else if (currentState === 300) {
        return "Generating a 3D environment in Unreal Engine, step 2 / 2";
    } else {
        return "Generating..."
    }
}

const getAssetServer = (): string => {
    return import.meta.env.VITE_APP_BASE_URL + import.meta.env.VITE_APP_ASSET_FILE;
}

const getWebSocketServer = (): string => {
    if (window.location.protocol === 'https:') {
        return import.meta.env.VITE_APP_WSS;
    } else {
        return import.meta.env.VITE_APP_WS;
    }
}

const getWebSocketURL = (): string => {
    var taskId = localStorage.getItem('task_id')
    var url: string;
    if (window.location.protocol === 'http:') {
        url = import.meta.env.VITE_APP_WS + taskId;
    } else {
        url = import.meta.env.VITE_APP_WSS + taskId;
    }
    return url;
}

// 定义一个函数来根据 id 获取图像的 src
const getImageSrcById = (id: number, initImages: Array<ImageList>): string => {
    const image = initImages.find(image => image.id === id);
    return image ? image.src : initImages[0].src;
};

const BlockNavigation = (disabled: boolean): void => {
    let alist = document.getElementsByTagName('a');
    for (let i = 0; i < alist.length; i++) {
        alist[i].onclick = function (event: any) {
            if (disabled) {
                event.preventDefault();
                event.stopPropagation();
                message.warning("Task in progress, please do not switch pages");
            }
        }
    }
}

function loadImage(url: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.crossOrigin = 'anonymous';
        image.src = url;
        image.onload = function () {
            resolve(image);
        };
        image.onerror = function (error) {
            reject(error);
        }
    })
}

function loadReader(data: any): Promise<string> {
    return new Promise((resolve, reject) => {
        const blob = new Blob([data], { type: 'image/png' });
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = function (event) {
            let url = event.target?.result as string;
            resolve(url);
        };
        reader.onerror = function (error) {
            reject(error);
        };
    })
}

function filterData(arr: Array<Task>): Array<Task> {
    return arr.filter(item => !(item.status >= 240 && item.status <= 250) && item.status >= 210);
}

function reverseSort(data: Array<Task>): Array<Task> {
    data.sort((a, b) => {
        return parseInt(b.task_id) - parseInt(a.task_id);
    });
    return data;
}

function formatDateString(input: string): string {
    if (input.length < 8) {
        throw new Error("Input string is too short to format.");
    }

    const year = input.substring(0, 4);
    const month = input.substring(4, 6);
    const day = input.substring(6, 8);

    return `${year}-${month}-${day}`;
}

async function downloadCheck(task_id: string): Promise<any> {
    if (!task_id) {
        message.warning("No files to download.");
        return
    }
    let checkURL = import.meta.env.VITE_APP_CHECK_UELEVEL + task_id;
    let response;
    try {
        response = await request.get(checkURL);
    } catch (error) {
        message.warning("Server internal error");
    }

    if (!response?.data.result) {   // result=0 not ready, result=1 ready.
        message.warning("The file is not ready yet.");
        return false
    } else {
        return true;
    }
}

function updateState(state: GenerateState, taskTempStatus: any, type: string, status: number, incrementTime = 2): void {
    const isModel = type === "model";
    const isUE = type === "ue";

    if (state.currentState !== status) {
        state.currentState = status;
        taskTempStatus.current = status;

        if (isModel) {
            if (status !== 210) {
                state.time = 0;
                state.total_time = 0;
                state.taskAhead = 0;
            }
            state.text = "Generating .fbx";
        } else if (isUE) {
            if (status !== 360) {
                state.time = 0;
                state.taskAhead = 0;
                state.renderAhead = 0;
            }
            state.text = "Generate high-quality scenes";
        }
    } else {
        state.time += incrementTime;
        state.total_time += incrementTime;
    }

    if (state.value <= 96) {
        const incrementValue = isModel ? (100 / 120) * incrementTime : isUE ? (100 / 600) * incrementTime : 0;
        state.value += incrementValue;
    }
}

async function checkUserLogin(token: string): Promise<boolean> {
    let url = import.meta.env.VITE_APP_BASE_URL + import.meta.env.VITE_APP_CHECK_LOGIN;
    try {
        var response = await fetch(url, { method: "GET", headers: { "x-id-token": token } });
        if (response.status === 403) {
            return false
        }
        return true
    } catch (error) {
        message.error("Network error")
        return false
    }
}

function formatTimeUnit(unit:number) {
    return unit < 10 ? `0${unit}` : `${unit}`;
}

function convertToTime(value:number) {
    // Convert the value from hours to hours and minutes
    const hours = Math.floor(value);
    const minutes = Math.round((value - hours) * 60);
    
    // Format hours and minutes to two digits
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    
    return `${formattedHours} : ${formattedMinutes} : 00`;
}

export {
    disabledButton,
    enabledButton,
    setLocalToken,
    getLocalToken,
    clearLocalToken,
    toSocketSend,
    GenerateUEProgress,
    getImageSrcById,
    getWebSocketURL,
    BlockNavigation,
    reverseSort,
    loadImage,
    loadReader,
    filterData,
    getWebSocketServer,
    getAssetServer,
    formatDateString,
    downloadCheck,
    updateState,
    checkUserLogin,
    formatTimeUnit,
    convertToTime
}