import { formatDateString } from "@/lib/utils";
import { TaskCardProps } from "@/types";
import "./taskCard.css";

const taskProgress: { [key: number]: string } = {
    100: "Generating Model",
    200: "Generating Model",
    210: "Model completed",
    220: "Waiting",
    230: "Generating .geo files",
    241: "Failed",
    242: "Failed",
    260: "Waiting",
    300: "Generating UE scene",
    360: "UE executing",
    371: ".geo file download failed",
    900: "Ready"
};

const fontColor = (status: number) => {
    if (status === 241 || status === 242) return { color: "red" }
    if (status === 900) return { color: "#53D2EE" }
    return {}
}

function TaskCard({ imgSrc, status, taskID, viewClick, deleteClick }: TaskCardProps) {
    return (
        <div className="w-[14rem] p-3 rounded-lg container text-xs">
            <img src={imgSrc} alt={taskID} style={{ minHeight: "200px" }} />
            <p className="pt-1 flex justify-between">
                <span style={fontColor(status)} >{taskProgress[status]}</span>
                <span className="date">{formatDateString(taskID)}</span>
            </p>
            {/* <p>ID: {taskID}</p> */}
            <div className="flex space-x-2">
                <button onClick={() => viewClick(taskID)} className="mt-1 py-1 flex-1 rounded-sm view-btn">View</button>
                <button onClick={() => deleteClick(taskID)} className="mt-1 py-1 flex-1 rounded-sm view-btn">Delete</button>
            </div>
        </div>
    );
}
export default TaskCard;