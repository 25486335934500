import { useState, useRef, useEffect, useId } from "react";
import { useSearchParams } from "react-router-dom";
import { AxiosError, AxiosResponse } from 'axios';
import { message, notification } from 'antd';
import { useUpdateEffect, useFullscreen } from "ahooks";
import ThreeScene from "@/components/ThreeScene/ThreeScene";
import UEScene from "@/components/UEScene/UEScene";
import UEProgress from "@/components/UEProgress";
import ModelProgress from "@/components/ModelProgress";
// @ts-ignore
import Mapgen from "@/components/Mapgen/Mapgen";
import { createTask, revisionTask, taskStatus, confirmTask, closeTask, taskRender, getModelURL } from "@/service/taskService";
import { disabledButton, enabledButton, loadImage, getLocalToken, updateState, downloadCheck } from "@/lib/utils";
import { hudiniNotification, reconnectUESceneNotice, ueNotification } from "@/lib/notification";
import { GenerateState, Pos, drawBoardRef } from "@/types";
import fullScreenIcon from "@/assets/fullscreen.svg";
import fullScreenExitIcon from "@/assets/fullscreen_exit.svg";
import downloadIcon from "@/assets/download.svg";
import "@/App.css";
import "./generate.css";


const ASSET_MODEL = import.meta.env.VITE_APP_BASE_URL + import.meta.env.VITE_APP_ASSET_MODEL;
const MODEL = import.meta.env.VITE_APP_BASE_URL + import.meta.env.VITE_APP_DOWNLOAD_MODEL;

export default function Generate() {
    const [searchParams] = useSearchParams();
    const drawBoardRef = useRef<drawBoardRef>(null);
    const [genPersceneLoading, setGenPersceneLoading] = useState<boolean>(false);
    const [generateLoading, setGenerateLoading] = useState<boolean>(false);
    const [perfectionScene, setPerfectionScene] = useState<boolean>(false);
    const [modelUrl, setModelUrl] = useState<string>("");
    const queryType = useRef<string>("model");
    const timerRef = useRef<string | number | undefined | NodeJS.Timeout>(undefined);
    const isModelDone = useRef<boolean>(false);
    const taskid = useRef<string>("");
    const [taskID, setTaskID] = useState<string>("");
    const taskTempStatus = useRef<number>(0);
    const isRevision = useRef<boolean>(false);
    const generateState = useRef<GenerateState>({ text: "null", time: 0, total_time: 0, value: 0, currentState: 0, taskAhead: 0, renderAhead: 0 });
    const modelFullRef = useRef(null);
    const [_, setUpdateGenerateState] = useState<boolean>(false);   // 用于更新generateState
    const [isUeFullScreen, setIsUeFullScreen] = useState<boolean>(false);
    const [triggerPollingTask, setTriggerPollingTask] = useState<boolean>(false);
    const [triggerGetModelURL, setTriggerGetModelURL] = useState<boolean>(false);
    const [triggerConnectWS, setTriggerConnectWS] = useState<boolean>(false);
    const fullScreenBtnRef = useRef<HTMLButtonElement>(null);
    const [isModelFullscreen, { enterFullscreen: enterModelFullscreen, exitFullscreen: exitModelFullscreen }] = useFullscreen(modelFullRef);
    const notifiKey = useId();

    useEffect(() => {
        /**
         * 判断是否是从历史页面进来的，
         * 如果是历史记录进行查看
         * 则执行historyTaskView
        */
        const historyTaskId = searchParams.get('id');
        historyTaskId && historyTaskView();

        const script = document.createElement('script');
        script.src = './js/mp4box.all.min.js';
        script.async = true;
        document.body.appendChild(script);

        // 阻止使用空格键控制全屏
        const blockFullScreen = (event: KeyboardEvent) => {
            if (event.code === 'Space' && document.activeElement === fullScreenBtnRef.current) event.preventDefault();
        };
        document.addEventListener('keydown', blockFullScreen);
        return () => {
            document.removeEventListener('keydown', blockFullScreen);
            document.body.removeChild(script);
            notification.destroy(notifiKey);
            timerRef.current && clearInterval(timerRef.current);
        };
    }, []);

    // 处理查看历史记录预览
    const historyTaskView = async () => {
        const historyTaskId = searchParams.get('id');
        var response;
        try {
            response = await taskStatus(historyTaskId!);
        } catch (error) {
            message.error("Error in querying task status!");
        }
        var status = response?.data.status;
        if (status === undefined) return
        status = parseInt(status);

        if (status >= 210) {
            // 加载历史记录中的模型
            var modelURL = ASSET_MODEL + historyTaskId + "/scene.fbx";
            setModelUrl(modelURL);
        }
        // 从历史记录进来以后同步当前页面中taskid和generate-state
        taskid.current = historyTaskId!;
        generateState.current.currentState = status;
        taskTempStatus.current = status;

        // 在画板上绘制历史记录中的图片
        let mapURL = import.meta.env.VITE_APP_BASE_URL + import.meta.env.VITE_APP_ASSET_FILE + historyTaskId + "/map.png";

        let image = await loadImage(mapURL);
        drawBoardRef.current?.canvasClear();
        drawBoardRef.current?.boardDrawImage(image);
        drawBoardRef.current?.addChooseImage();

        if (status === 100 || status === 200) {
            setGenerateLoading(true);
            disabledButton();
            setTriggerPollingTask(!triggerPollingTask);
        }

        if (status === 360) {
            setTriggerConnectWS(!triggerConnectWS);
        }

        if (status === 220 || status === 230 || status === 260 || status === 300) {
            disabledButton();
            queryType.current = "ue";
            setGenPersceneLoading(true);
            setTriggerPollingTask(!triggerPollingTask);
        }
    }

    // 从历史记录中快速生成UE场景，当status是900时
    const historyGenerateScene = async () => {
        if (generateState.current.time || generateState.current.value) {
            generateState.current.time = 0;
            generateState.current.value = 0;
        }
        disabledButton();
        try {
            await taskRender(taskid.current);
            setGenPersceneLoading(true);
            setTriggerPollingTask(!triggerPollingTask);
        } catch (error) {
            console.error(error);
            message.error("Error in generating UE scene!");
        }
        //enabledButton();
    }

    const reconnect = async () => {
        setTaskID("");
        setPerfectionScene(false);
        reconnectUESceneNotice(tryToReconnect, notifiKey);
    }

    const tryToReconnect = () => {
        return <button onClick={() => {
            setTriggerConnectWS(!triggerConnectWS);
        }}>Try to reconnect</button>
    };

    // 触发轮询任务
    useUpdateEffect(() => {
        timerRef.current && clearInterval(timerRef.current);
        timerRef.current = setInterval(() => {
            queryStatusTask(taskid.current);
        }, 2000);
    }, [triggerPollingTask]);

    // 触发获取模型
    useUpdateEffect(() => {
        getModelUrl(taskid.current);
    }, [triggerGetModelURL]);

    // 触发链接websocket
    useUpdateEffect(() => {
        // notification.destroy(notifiKey);
        async function connect() {
            var response;
            try {
                response = await taskStatus(taskid.current);
            } catch (error) {
                // @ts-ignore
                console.log("error:", error.message);
                reconnectUESceneNotice(tryToReconnect, notifiKey);
            }

            if (response?.data.status === "360") {
                setTaskID(taskid.current);
                setPerfectionScene(true);
                notification.destroy(notifiKey);
            }

            if (response?.data.status === "900") {
                generateState.current.currentState = 900;
                setUpdateGenerateState(prev => !prev);
                historyGenerateScene();
                notification.destroy(notifiKey);
            }

        }
        connect();
    }, [triggerConnectWS]);

    //  获取模型URL
    const getModelUrl = async (task_id: string) => {
        var url = ASSET_MODEL + task_id + "/scene.fbx";
        console.log("url", url);
        try {
            var response = await getModelURL(task_id);;
            setGenerateLoading(false);
            clearInterval(timerRef.current);
            response.status === 200 && setModelUrl(url);
            isModelDone.current = false; // 当轮询状态为210时表示模型生成完毕
        } catch (error) {
            clearInterval(timerRef.current);
            message.error("An error occurred at the server level");
        }
        setGenerateLoading(false);
    }

    // 获取轮询状态
    const queryStatusTask = async (task_id: string) => {
        var response: AxiosResponse;
        var state = generateState.current;
        try {
            response = await taskStatus(task_id);
            var status = parseInt(response.data.status);
            if (status === 100) {
                state.taskAhead = response.data.task_queue_ahead;
            };

            if (status === 210) {
                // 当轮询状态为210时表示模型生成完毕,此时触发模型下载
                setTriggerGetModelURL(!triggerGetModelURL);
                clearInterval(timerRef.current);
                enabledButton();
            };

            if (status === 220 || status === 260) {
                state.taskAhead = response.data.task_queue_ahead;
                state.renderAhead = response.data.render_queue_ahead;
            }

            if (status >= 240 && status < 250) {
                generateState.current.value = 0;
                generateState.current.time = 0;
                setGenPersceneLoading(false);
                setGenerateLoading(false);
                clearInterval(timerRef.current);
                enabledButton();
                message.error({
                    content: "The current image cannot generate a model, please change the image",
                    duration: 10
                });
            };

            if (status === 360) {
                clearInterval(timerRef.current);
                setTriggerConnectWS(!triggerConnectWS); // 触发链接websocket
                setGenPersceneLoading(false);
                enabledButton();
            };

            if (status >= 370 && status < 380) {
                clearInterval(timerRef.current);
                enabledButton();
                setGenPersceneLoading(false);
                message.error({
                    content: "An error occurred while generating the UE scene.",
                    duration: 10
                });
            };
            updateState(state, taskTempStatus, queryType.current, status);
            setUpdateGenerateState(prev => !prev);
        } catch (error) {
            setGenerateLoading(false);
            clearInterval(timerRef.current);
            message.error(`An error occurred:, ${(error as AxiosError)?.message}`);
            enabledButton();
        };
    }

    // 生成模型
    const generateModel = async (point: Pos, blob: Blob, blob2: Blob, _: Function): Promise<void> => {
        let isNotice = hudiniNotification(taskTempStatus.current);
        if (isNotice) return
        queryType.current = "model";
        var response: AxiosResponse;
        taskID && closeWS();  // 当再次生成新的模型时关闭
        modelUrl && setModelUrl("");
        setGenerateLoading(true);
        disabledButton();
        // 当轮询状态为210时表示模型生成完毕
        isModelDone.current = true;
        generateState.current.value = 0;

        // const blob = await drawBoardRef.current?.toBlob();
        const formData = new FormData();
        formData.append('files', blob, 'map.png');
        formData.append("files", blob2, "map_h.png");
        const jsonObject = {
            village: point
        };
        console.log(jsonObject);
        
        const jsonString = JSON.stringify(jsonObject);        
        const jsonBlob = new Blob([jsonString], { type: 'application/json' });
        formData.append('files', jsonBlob, 'data.json');
        
        try {
            if (isRevision.current) { // true 修改的图片, false 新的图片
                response = await revisionTask(formData, taskid.current)
            } else {
                response = await createTask(formData);
                taskid.current = response.data.task_id;
                localStorage.setItem('task_id', response.data.task_id);
                /**
                 * 当第一次创建任务后，将isRevision设置为true，
                 * 当再次请求时不在创建新任务，
                 * 直到用户点击了其他的图片时，将isRevision置为false
                 * */
                isRevision.current = true;
            }
            // enable();   // 解除生成3d按钮的禁用
        } catch (error) {
            // enable();
            setGenerateLoading(false);
            //message.error(`An error occurred:, ${(error as AxiosError)?.message}`);
            return;
        };
        // enable();
        setTriggerPollingTask(!triggerPollingTask);  // 触发轮询任务
    }

    // 展示生成UE场景
    const showPerfectionScene = async (task_id: string) => {
        let isNotice = ueNotification(taskTempStatus.current);
        if (isNotice) return
        queryType.current = "ue";
        setGenPersceneLoading(true);
        disabledButton();
        generateState.current.value = 0;
        try {
            var response = await confirmTask(task_id);
            if (response.status === 200) {
                setTriggerPollingTask(!triggerPollingTask);
            }
        } catch (error) {
            clearInterval(timerRef.current);
            message.error(`An error occurred:, ${(error as AxiosError)?.message}`);
        }
    }

    // 关闭UE连接
    const closeWS = async () => {
        try {
            if (generateState.current.currentState >= 360) {
                var response = await closeTask(taskid.current);
                console.log("关闭状态：", response.status);
                generateState.current.currentState = 900;
                setUpdateGenerateState(prev => !prev);
            }
            setPerfectionScene(false);
            setTaskID("");
        } catch (error) {
            message.error(`An error occurred:, ${(error as AxiosError)?.message}`);
        }
    }

    const buttonStyle = { backgroundColor: generateLoading || genPersceneLoading ? "#ccc" : "" };

    return (
        <main className="py-2 pt-0 flex-1">
            <div className="flex pr-6 pl-6 h-full">
                <div className="flex flex-col">
                    <div className="px-6 py-6 pt-3 rounded-md bg-gradient flex-1">
                        <div className=" text-white font-light flex items-center justify-between pb-2">
                            <p className=" flex items-center">
                                <span className=" w-5 h-5 flex border border-white rounded-xl text-sm justify-center items-center mr-2 bg-[#6442A0]">1</span>
                                <span>Generate your environment with a sketch map</span>
                            </p>
                            <p className=" cursor-pointer" onClick={() => { window.location.href = "/" }}>
                                NewTask
                            </p>
                        </div>
                        <div className=" max-w-[36rem]">
                            <Mapgen
                                loading={generateLoading}
                                callback={generateModel} />
                        </div>
                    </div>
                    <div className="text-[#666] text-xs">
                        <span>close</span>
                    </div>
                </div>

                <div className="flex-1 pl-4 flex flex-col">
                    <div className={`flex-1 w-full flex flex-col justify-center m-auto bg-[#191919] rounded-md text-[#5E5E5E] mb-4 px-4 pt-0`} ref={modelFullRef}>
                        <div className="flex justify-between">
                            <p className=" flex items-center text-white py-2 text-base font-light">
                                <span className=" w-5 h-5 flex border border-white rounded-xl text-sm justify-center items-center mr-2">2</span>
                                <span>3D preview</span>
                            </p>
                            <div className="flex items-center">
                                <button onClick={() => {
                                    if (!modelUrl) {
                                        message.warning("No files to download.");
                                        return
                                    }
                                    var url = MODEL + taskid.current + `?token=${getLocalToken()}`;
                                    window.open(url, '_blank');
                                }}>
                                    <img src={downloadIcon} alt="" />
                                </button>
                                <button onClick={() => { isModelFullscreen ? exitModelFullscreen() : enterModelFullscreen() }} className="outline-none">
                                    <img src={isModelFullscreen ? fullScreenExitIcon : fullScreenIcon} alt="" className="ml-4 h-5" />
                                </button>
                            </div>
                        </div>
                        {
                            modelUrl ?
                                <ThreeScene
                                    isFull={isModelFullscreen}
                                    modelUrl={modelUrl}
                                />
                                : <div className="flex justify-center items-center flex-col text-sm h-full select-none">
                                    <ModelProgress
                                        loading={generateLoading}
                                        value={generateState.current.value}
                                        waiting={generateState.current.taskAhead}
                                    />
                                </div>
                        }
                        {
                            modelUrl && !isModelFullscreen ? <button className="text-sm px-2 py-2 my-2 rounded-md w-[20rem] text-black bg-white hover:bg-gray-200"
                                onClick={() => showPerfectionScene(taskid.current)}
                                style={buttonStyle}
                            >{genPersceneLoading ? "generating..." : "Generate 3D environment in Unreal Engine"}</button> : ""
                        }
                    </div>
                    <div className=" flex-1 w-full rounded-md bg-[#191919] text-[#5E5E5E] overflow-hidden">
                        <div className={`text-white text-sm h-full ${isUeFullScreen ? " fixed top-0 left-0 w-full bg-[#191919]" : ""}`}>
                            <div className="flex justify-between pl-4 pt-3 pr-4 pb-3">
                                <div className="flex items-center">
                                    <span className=" w-5 h-5 flex border border-white rounded-xl text-sm justify-center items-center mr-2">3</span>
                                    <span>3D environment</span>
                                </div>
                                <div className="flex items-center">
                                    <button onClick={closeWS}>
                                        Close
                                    </button>
                                    <button
                                        onClick={async () => {
                                            let check = await downloadCheck(taskid.current);
                                            if (check) {
                                                let url = `https://auto3d.blob.core.windows.net/automation-3d/cybever-3d/download.html?env=${import.meta.env.VITE_APP_MODE}&taskid=${taskid.current}&token=${getLocalToken()}`
                                                window.open(url, "_blank");
                                            }
                                        }}
                                        className="ml-4"
                                    >
                                        <img src={downloadIcon} alt="" />
                                    </button>
                                    <button ref={fullScreenBtnRef} onClick={() => setIsUeFullScreen(!isUeFullScreen)} className="outline-none">
                                        <img src={isUeFullScreen ? fullScreenExitIcon : fullScreenIcon} alt="" className=" h-5 ml-4" />
                                    </button>
                                </div>
                            </div>
                            {perfectionScene && taskID ? <UEScene taskId={taskID} callback={reconnect} /> : ""}
                            <div className="flex justify-center items-center flex-col text-sm h-full select-none text-[#5E5E5E]">
                                <UEProgress
                                    status={generateState.current.currentState}
                                    value={generateState.current.value}
                                    total_time={generateState.current.total_time}
                                    btnStyle={buttonStyle}
                                    loading={genPersceneLoading}
                                    genScene={historyGenerateScene}
                                    taskAhead={generateState.current.taskAhead}
                                    renderAhead={generateState.current.renderAhead}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="text-[#666] text-xs space-x-2">
                        <span>status: {generateState.current.currentState}, time:{generateState.current.time}s, {generateState.current.text}</span>
                        <span>task id:{taskid.current}</span>
                    </div>
                </div>
            </div>
        </main>
    );
}
