import { ChangeEvent, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { auth, provider } from "@/lib/firebase";
import { useDispatch } from "react-redux";
import { setToken } from "@/store/reducer/user";
import { checkUserLogin, setLocalToken } from "@/lib/utils";
import googleIcon from "@/icon/google.png";
import { notification } from "antd";

function Login() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [checked, setChecked] = useState(false);
    const [checkMsg, setCheckMsg] = useState(false);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setChecked(e.target.checked);
        setCheckMsg(false);
    };

    const loginHandle = async () => {
        if (!checked) {
            setCheckMsg(true);
            return
        };

        try {
            let result = await signInWithPopup(auth, provider);
            const user = result.user;
            let token = await user.getIdToken();
            let isAuthorization = await checkUserLogin(token);
            if (!isAuthorization) {
                notification.open({
                    message: `The EnGen 3D world creation is currently open to invited users. 
                    We will be launching it to the public soon. Stay tuned!`,
                    placement: "topLeft",
                    duration: 10,
                    showProgress: true,
                    btn: <a href="https://www.cybever.ai/form" target="_blank">Contact Us</a>
                });
                return
            }
            localStorage.setItem("photoURL", user.photoURL!);
            dispatch(setToken(token));
            setLocalToken(token);
            setTimeout(() => navigate("/"), 0);
        } catch (error: any) {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.customData.email;
            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error);
            console.log(errorCode, errorMessage, email, credential);
        }
    }

    return (
        <div className=" w-full h-full flex justify-center items-center text-white">
            <div className="text-center">
                <img src="/image/logo.png" alt="" width={300} className=" mb-10" />
                <button
                    className="w-full rounded-lg py-1 border border-1 border-gray-800 flex items-center justify-center hover:bg-gray-800 transition duration-300"
                    onClick={loginHandle}
                >
                    <img src={googleIcon} alt="" width={30} height={30} />&nbsp;&nbsp;&nbsp;&nbsp;
                    <span>google Login</span>
                </button>
                <div className="flex justify-center mt-4 text-sm flex-wrap">
                    <p className="flex items-center">
                        <input
                            type="checkbox"
                            checked={checked}
                            onChange={handleChange}
                            id="agreement"
                            className={`mr-2 ${!checkMsg ? "" : "verification-checkbox"}`}
                        />
                        <label htmlFor="agreement">I understand and agree to &nbsp;</label>
                    </p>
                    <Link to="/agreement" target="_blank" className="underline text-blue-300">the user agreement.</Link>
                </div>
                <div className="text-sm text-red-500 flex justify-end h-6">
                    {!checkMsg ? "" : "Please read the agreement and check the box."}
                </div>
            </div>
        </div>
    )
}

export default Login;