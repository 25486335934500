import type { MenuProps } from 'antd';
import { Dropdown } from 'antd';
import Logo from "../icon/logo.png";
import { signOut } from 'firebase/auth';
import { auth } from '@/lib/firebase';
import { removeToken } from '@/store/reducer/user';
import { useDispatch } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';

const items: MenuProps['items'] = [
    {
        key: "userAgreement",
        label: <button>User Agreement</button>
    },
    {
        key: 'signOut',
        label: (
            <button>signOut</button>
        ),
    },
]

function Header() {
    var navigator = useNavigate();
    const photoURL = localStorage.getItem("photoURL")!;
    const dispatch = useDispatch();
    const handleLogout: MenuProps['onClick'] = (e) => {
        if (e.key === 'signOut') {
            signOut(auth).then(() => {
                dispatch(removeToken());
                localStorage.removeItem("token_key");
                console.log("Signed out successfully")
            }).catch((error) => {
                console.log(error);
            });
        }
        if (e.key === 'userAgreement') {
            navigator("/agreement");
        }
    };

    return (
        <nav className="h-16 flex justify-between items-center px-6 min-h-[3.8rem]">
            <div className="flex items-center text-white font-thin">
                <div className=' flex items-center cursor-pointer' onClick={() => window.location.href = "/"}>
                    <img src={Logo} alt="" className="w-6" />
                    <span className="ml-4 text-xl italic">Cybever</span>
                </div>
                <div className='route-link ml-16 space-x-4'>
                    <NavLink to="/" className=" ">Generate</NavLink>
                    <NavLink to="/history">History</NavLink>
                </div>
            </div>

            <div className='flex text-white items-center'>
                {/* <a href="/agreement" target='_blank' className=" mr-3 text-sm">Use Agreement</a> */}
                <Dropdown menu={{ items, onClick: handleLogout, }} placement="bottomRight">
                    <img src={photoURL} alt="" width={30} height={30} className=" rounded-full cursor-pointer" />
                </Dropdown>
            </div>
        </nav>
    );
}

export default Header;